import { render, staticRenderFns } from "./completedProcessing.vue?vue&type=template&id=2a947968&scoped=true"
import script from "./completedProcessing.vue?vue&type=script&lang=js"
export * from "./completedProcessing.vue?vue&type=script&lang=js"
import style0 from "./completedProcessing.vue?vue&type=style&index=0&id=2a947968&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a947968",
  null
  
)

export default component.exports